import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
import TypeScaleTable from '../../../../src/components/TypeScaleTable';
import TypeWeight from '../../../../src/components/TypeWeight';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const DoDontExample = makeShortcode("DoDontExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h3 {...{
      "id": "typography-can-help-create-clear-hierarchies-organize-information-and-guide-users-through-a-product-or-experience"
    }}>{`Typography can help create clear hierarchies, organize information, and guide users through a product or experience.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Type tokens and sets</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Typeface: IBM Plex</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Scale</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Style</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Type color</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <Row className="tile--group" mdxType="Row">
      <Column offsetLg="4" colMd="4" colLg="4" noGutterSm mdxType="Column">
  <ResourceCard title="Get the latest IBM Plex™ download on GitHub" href="https://github.com/IBM/plex/releases/latest" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "github icon",
              "title": "github icon",
              "src": "/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png",
              "srcSet": ["/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colMd="4" colLg="4" noGutterSm mdxType="Column">
  <ResourceCard title="Visit the IBM Plex™ website to learn more" href="https://www.ibm.com/plex/" type="resource" mdxType="ResourceCard">
  </ResourceCard>
      </Column>
      <Column offsetLg="4" colMd="4" colLg="4" noGutterSm mdxType="Column">
  <ResourceCard title="Elements package: Type" href="https://github.com/IBM/carbon-elements/tree/master/packages/type" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "github icon",
              "title": "github icon",
              "src": "/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png",
              "srcSet": ["/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colMd="4" colLg="4" noGutterSm mdxType="Column">
  <ResourceCard title="Type package preview" href="https://github.com/IBM/carbon-elements/tree/master/packages/type" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "sandbox icon",
              "title": "sandbox icon",
              "src": "/static/6c228cfe2ab2ee15d1c722c13f3eced0/7d71f/sandbox-icon.png",
              "srcSet": ["/static/6c228cfe2ab2ee15d1c722c13f3eced0/7d71f/sandbox-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
 </ResourceCard> 
      </Column>
    </Row>
    <br />
    <br />
    <h2 {...{
      "id": "type-tokens-and-sets"
    }}>{`Type tokens and sets`}</h2>
    <p>{`Carbon uses type tokens and themes to manage typography. Type tokens are pre-set configurations of typographic elements such as font size, weight, or leading (line height) that are specifically calibrated for use alongside `}<a parentName="p" {...{
        "href": "http://ibm.com/plex"
      }}>{`IBM Plex`}</a>{` in product. Selecting the appropriate type style is determined by layout or template structure. Layouts may have several levels of architecture or areas that require varying typographic hierarchies.`}</p>
    <h3 {...{
      "id": "productive-vs-expressive-type-sets"
    }}>{`Productive vs. Expressive type sets`}</h3>
    <p>{`Productive type is reserved for use in web-based product design, where the user needs to focus on tasks. The Productive styles are curated to create a series of clear user expectations about hierarchy. Expressive type, on the other hand, allows for a more dramatic, graphic use of type in editorial and marketing design — which many users would find distracting in product.`}</p>
    <p>{`There are two heading sets, one Productive and one Expressive. The difference between the Productive and Expressive styles is mainly evident in the Headings. Aside from the token names, which are specifically calibrated for product vs. editorial designers (e.g. $label-01 vs. $caption-01) — the Supporting and Body styles have the same values in both the Productive and Expressive sets.`}</p>
    <h2 {...{
      "id": "typeface-ibm-plex"
    }}>{`Typeface: IBM Plex`}</h2>
    <p>{`Carbon uses the open-source typeface `}<strong parentName="p">{`IBM Plex`}</strong>{`. It has been carefully designed to meet IBM’s needs as a global technology company and reflect IBM’s spirit, beliefs, and design principles. IBM Plex can be accessed and downloaded from the `}<a parentName="p" {...{
        "href": "https://github.com/ibm/plex"
      }}>{`Plex GitHub Repo`}</a>{`.`}</p>
    <TypeWeight type="types" mdxType="TypeWeight" />
    <h3 {...{
      "id": "sans-serif-font-stack"
    }}>{`Sans-serif font stack`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`font-family: 'IBM Plex Sans', 'Helvetica Neue', Arial, sans-serif;
`}</code></pre>
    <h3 {...{
      "id": "serif-font-stack"
    }}>{`Serif font stack`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`font-family: 'IBM Plex Serif', 'Georgia', Times, serif;
`}</code></pre>
    <h3 {...{
      "id": "mono-font-stack"
    }}>{`Mono font stack`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-scss"
      }}>{`font-family: 'IBM Plex Mono', 'Menlo', 'DejaVu Sans Mono',
  'Bitstream Vera Sans Mono', Courier, monospace;
`}</code></pre>
    <h2 {...{
      "id": "scale"
    }}>{`Scale`}</h2>
    <p>{`The IBM type scale is built on a single equation. The formula for our scale was created to provide hierarchy for all types of experiences. The formula assumes that y₀=12 px:`}</p>
    <TypeScaleTable mdxType="TypeScaleTable" />
    <pre><code parentName="pre" {...{}}>{`Xn = Xn-1 + {INT[(n-2)/4] + 1} * 2
Xn: step n type size Xn-1: step n-1 type size
`}</code></pre>
    <h2 {...{
      "id": "style"
    }}>{`Style`}</h2>
    <p>{`Typography creates purposeful texture, guiding users to read and understand the hierarchy of information. The right typographic treatment and the controlled usage of type styles helps manage the display of content, keeping it useful, simple, and effective.`}</p>
    <h3 {...{
      "id": "weights"
    }}>{`Weights`}</h3>
    <p>{`Font weight is an important typographic variable that can add emphasis and differentiate content hierarchy. Font weight and size pairings must be carefully balanced. A bold weight will always have more emphasis than a lighter weight font of the same size. However, a lighter weight font can rank hierarchically higher than a bold font if the lighter weight type size is significantly larger than the bold one.`}</p>
    <p>{`We suggest using IBM Plex Light, Regular, and SemiBold for digital experiences. The semibold weight is ideal for section headers, but should not be used for long text.`}</p>
    <TypeWeight mdxType="TypeWeight" />
    <h3 {...{
      "id": "italic"
    }}>{`Italic`}</h3>
    <p>{`Each weight has an italic style, which should only be used when you need to emphasize certain words in a sentence (titles of works, technical terms, names of devices, captions, etc.).`}</p>
    <TypeWeight type="italic" mdxType="TypeWeight" />
    <h2 {...{
      "id": "type-color"
    }}>{`Type color`}</h2>
    <p>{`Type color should be carefully considered, with legibility and accessibility as paramount concerns. Keep type color neutral in running text. Use primary blue for primary actions.`}</p>
    <Row mdxType="Row">
      <Column offsetLg="4" colMd="4" colLg="4" mdxType="Column">
        <DoDontExample correct mdxType="DoDontExample">
          <img {...{
            "src": "/Typography_overview_Type-color-1-c62b2ead82d277c708c6478416bc2ed7.svg",
            "alt": "Neutral color for text"
          }}></img>
        </DoDontExample>
      </Column>
      <Column colMd="4" colLg="4" mdxType="Column">
        <DoDontExample mdxType="DoDontExample">
          <img {...{
            "src": "/Typography_overview_Type-color-2-6dd70c4eb05b74e0e64e319efb96e9ae.svg",
            "alt": "I'm a magenta sentence for no reason"
          }}></img>
        </DoDontExample>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column offsetLg="4" colMd="4" colLg="4" mdxType="Column">
        <DoDontExample correct dark="true" mdxType="DoDontExample">
          <img {...{
            "src": "/Typography_overview_Type-color-3-bd205bd9e609d2c3fa668c47f287d18e.svg",
            "alt": "Neutral color for text"
          }}></img>
        </DoDontExample>
      </Column>
      <Column colMd="4" colLg="4" mdxType="Column">
        <DoDontExample dark="true" mdxType="DoDontExample">
          <img {...{
            "src": "/Typography_overview_Type-color-4-85e6ec7a8f025768a2fb99158980ebaf.svg",
            "alt": "Color is not for decoration"
          }}></img>
        </DoDontExample>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column offsetLg="4" colMd="4" colLg="4" mdxType="Column">
        <DoDontExample correct label="Core blue colors are used for text links and primary actions" mdxType="DoDontExample">
          <img {...{
            "src": "/Typography_overview_Type-color-5-d71ae288b89e4368fb7dd5fdb9bdf49b.svg",
            "alt": "Link with icon"
          }}></img>
        </DoDontExample>
      </Column>
      <Column colMd="4" colLg="4" mdxType="Column">
        <DoDontExample correct label="Secondary actions use Gray 100 and icons" mdxType="DoDontExample">
          <img {...{
            "src": "/Typography_overview_Type-color-6-7470031ea82790f8813a4e3e9cb23e52.svg",
            "alt": "Download with icon"
          }}></img>
        </DoDontExample>
      </Column>
    </Row>
    <Row mdxType="Row">
      <Column offsetLg="4" colMd="4" colLg="4" mdxType="Column">
        <DoDontExample correct label="Other use cases for colored type are code snippets, warnings, alerts, etc." mdxType="DoDontExample">
          <img {...{
            "src": "/Typography_overview_Type-color-7-b42ebaf8b4289e1da89c9b184b6bec3f.svg",
            "alt": "Oops, something went wrong! colored text"
          }}></img>
        </DoDontExample>
      </Column>
      <Column colMd="4" colLg="4" mdxType="Column">
        <DoDontExample correct mdxType="DoDontExample">
          <img {...{
            "src": "/Typography_overview_Type-color-8-b73c46a93802fc24874a9f3159a16d63.svg",
            "alt": "Code snippet with colored highlight type"
          }}></img>
        </DoDontExample>
      </Column>
    </Row>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      